<template>
  <b-card
    border-variant="transparent"
    bg-variant="transparent"
    class="shadow-none"
  >
    <!-- about -->
    <h3 class="text-center mb-2">
      Status Permohonan
    </h3>
    <app-timeline>
      <app-timeline-item :variant="(statusProgram.draft !== '') ? 'info': 'primary'">
        <h6>Draft</h6>
        <p>{{ statusProgram.draft | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.draft_lengkap"
        :variant="(statusProgram.draft_lengkap) ? 'info': 'secondary'"
      >
        <h6>Draft Lengkap</h6>
        <p>{{ statusProgram.draft_lengkap | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.submit"
        :variant="(statusProgram.submit) ? 'info': 'secondary'"
      >
        <h6>Submit</h6>
        <p>{{ statusProgram.submit | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.verifikasi_teknis"
        :variant="(statusProgram.verifikasi_teknis) ? 'info': 'secondary'"
      >
        <h6>Verifikasi Teknis</h6>
        <p>{{ statusProgram.verifikasi_teknis | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.rekomendasi_sementara"
        :variant="(statusProgram.rekomendasi_sementara) ? 'info': 'secondary'"
      >
        <h6>Pemberian Rekomendasi</h6>
        <p>{{ statusProgram.rekomendasi_sementara | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.persetujuan_dirjen"
        :variant="(statusProgram.persetujuan_dirjen) ? 'info': 'secondary'"
      >
        <h6>Persetujuan Eselon 1</h6>
        <p>{{ statusProgram.persetujuan_dirjen | formatDate }}</p>
      </app-timeline-item>
      <app-timeline-item
        v-if="statusProgram.penerbitan_surat"
        :variant="(statusProgram.penerbitan_surat) ? 'info': 'secondary'"
      >
        <h6>Penerbitan Surat</h6>
        <p>{{ statusProgram.penerbitan_surat | formatDate }}</p>
      </app-timeline-item>
    </app-timeline>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      show: false,
      data_utama_id: '',
      statusProgram: {},
    }
  },
  mounted() {
    this.data_utama_id = this.$route.params.id
    this.getStatus()
  },
  methods: {
    getStatus() {
      this.$http.get('/clearance/core-data/get-status-kegiatan', {
        params: {
          token: localStorage.getItem('userToken'),
          data_utama_id: this.data_utama_id,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.statusProgram = res.data.data
          }
        })
    },
  },
}
</script>
